import React from 'react'
import zihuaLogo from '../../../../../assets/home/images/zihua-logo-full.svg'
import linkedIn from '../../../../../assets/home/images/linkedin.png'
import instagram from '../../../../../assets/home/images/instagram.png'
import twitter from '../../../../../assets/home/images/twitter.png'
import facebook from '../../../../../assets/home/images/facebook.png'
import './Footer.css'

export default function Footer() {
  return (
    <div className='Footer'>
      <div className='w-full flex flex-col lg:flex-row lg:gap-4 p-4'>

        <div className='flex flex-col justify-center items-center w-full lg:w-3/12'>
          <div>
            <a href="#home">
              <img src={zihuaLogo} alt="Zihua Logo" className='logo' />
            </a>
          </div>
          <div className='grid grid-cols-4 gap-4 mt-4'>
            <a href={process.env.REACT_APP_LINKED_IN} target='_Blank' rel='noreferrer'>
              <img src={linkedIn} alt="Linked In Logo" />
            </a>
            <a href={process.env.REACT_APP_INSTAGRAM} target='_Blank' rel='noreferrer'>
              <img src={instagram} alt="Instagram Logo" />
            </a>
            <a href={process.env.REACT_APP_X} target='_Blank' rel='noreferrer'>
              <img src={twitter} alt="X Logo" />
            </a>
            <a href={process.env.REACT_APP_FACEBOOK} target='_Blank' rel='noreferrer'>
              <img src={facebook} alt="Facebook Logo" />
            </a>
          </div>  
        </div>

        {/* mobile */}
        <div className='block lg:hidden'>
          <div className='w-full grid grid-cols-3 gap-2 mt-4'>
            <div>
              <a href="#home">
                Proyecto
              </a>
            </div>
            <div>
              <a href="#about">
                Amenidades
              </a>
            </div>
            <div>
              <a href="#location">
                Terreno
              </a>
            </div>
          </div>
          <div className='w-full grid grid-cols-3 gap-2 mt-2'>
            <div>
              <a href="#amenities">
                Financiamiento
              </a>
            </div>
            <div>
              <a href="#amenities">
                Compra tu lote
              </a>
            </div>
            <div>
              <a href="#contact">
                Contacto
              </a>
            </div>
          </div>
        </div>

        <div className='hidden lg:block'>
          <div className='w-full pl-12'>
            <div className='w-full grid grid-cols-6 gap-4 gap-md-2 gap-lg-4'>
              <div>
                <a href="#home">
                  Proyecto
                </a>
              </div>
              <div>
                <a href="#amenities">
                  Amenidades
                </a>
              </div>
              <div>
                <a href="#location">
                  Terreno
                </a>
              </div>
              <div>
                <a href="#location">
                  Financiamiento
                </a>
              </div>
              <div>
                <a href="#location">
                  Compra tu lote
                </a>
              </div>
              <div>
                <a href="#contact">
                  Contacto
                </a>
              </div>
            </div>
            <div className='flex justify-between text-white mt-24'>
              <div className='link'>
                Términos y condiciones
              </div>
              <div className='link'>
                Aviso de privacidad
              </div>
              <div className='-mt-7 text-right'>
              © 2023 Zihua <br />
                Todos los derechos reservados
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
