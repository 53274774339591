import React, { useEffect, useState } from 'react'
import "./DashboardPage.css"
import { useAuth } from '../../../hooks/useAuth'
import { roles } from '../../../constants/roles';
import DashboardSuperAdmin from './components/dashboardSuperAdmin/DashboardSuperAdmin'
import DashboardAdmin from './components/dashboardAdmin/DashboardAdmin'
import DashboardSeller from './components/dashboardSeller/DashboardSeller'
import DashboardClient from './components/dashboardClient/DashboardClient'
import DashboardCoordinator from './components/dashboardCoordinator/DashboardCoordinator';
import DashboardOrganization from './components/dashboardOrganization/DashboardOrganization';

export default function DashboardPage() {

const [role, setRole] = useState('');

  const { user } = useAuth();

  useEffect(() => {
    const theRole = user.user.roles ? user.user.roles[0].name.toLowerCase() : '';
    setRole(theRole);
  }, [user]);

  return (
    <div className='DashboardPage'>
      {
        role === roles.SUPER_ADMIN &&
        <DashboardSuperAdmin />
      }
      {
        role === roles.ADMIN &&
        <DashboardAdmin />
      }
      {
        role === roles.SELLER &&
        <DashboardSeller />
      }
      {
        role === roles.CLIENT &&
        <DashboardClient />
      }
      {
        role === roles.COORDINATOR &&
        <DashboardCoordinator />
      }
      {
        role === roles.ORGANIZATION &&
        <DashboardOrganization />
      }
    </div>
  )
}
