import React from 'react'
import banner from '../../../../../assets/home/images/about/mainbanner.png'
import logo from '../../../../../assets/home/images/about/icon-overlap.png'
import playa from '../../../../../assets/home/images/about/chicaplaya.png'
import Slider from '../Slider/Slider'
import './About.css'
import Carousel from 'react-multi-carousel'

import image1 from '../../../../../assets/home/images/carousel/about/image1.png'
import image2 from '../../../../../assets/home/images/carousel/about/image2.png'
import image3 from '../../../../../assets/home/images/carousel/about/image3.png'
import image4 from '../../../../../assets/home/images/carousel/about/image4.png'
import image5 from '../../../../../assets/home/images/carousel/about/image5.png'
import image6 from '../../../../../assets/home/images/carousel/about/image6.png'

const itemsAbout = [
  {
    image: image1,
    title: 'Foto 1'
  },
  {
    image: image2,
    title: 'Foto 2'
  },
  {
    image: image3,
    title: 'Foto 3'
  },
  {
    image: image4,
    title: 'Foto 4'
  },
  {
    image: image5,
    title: 'Foto 5'
  },
  {
    image: image6,
    title: 'Foto 6'
  }
];

export default function About() {

  return (
    <div className='About'>
      <div className="flex flex-row">
        <div className="basis-full">
          <Slider 
            items={itemsAbout}
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-center pb-16 relative z-5 bg-[#024E5B]">
        <div className='w-full grid grid-cols-1 lg:grid-cols-2'>
          <div className="w-full flex justify-center lg:justify-end">
            <div className='w-1/3 lg:w-2/3 flex justify-end pr-0 lg:pr-5'>
              <img src={logo} alt='two' className='w-full lg:w-2/3 -mt-5 sm:-mt-20 lg:-mt-20' />
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className='w-10/12 lg:w-9/12 flex justify-center items-center h-full'>
              <h2 className="title px-2 pt-4 lg:pt-0">
                Ser dueño de un terreno junto al mar pagándolo poquito a poquito, en cómodas mensualidades y sin intereses.
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="flex  flex-row ">
        <div className='block lg:hidden py-0 py-md-4'>
          <div className='w-full flex justify-center p-4 p-md-5 txt-blue lg:text-start'>
            Un residencial con las mejores amenidades y comodidades para los propietarios para que puedas:
          </div>
          <div className='md:block hidden flex justify-center px-2 px-md-5 px-lg-2'>
            <img src={playa} alt="playa" className="m-auto w-full" />
          </div>
          <div className='w-full px-4 flex justify-center'>
            <ul className="c-list pt-4">
              <li className="item-list">
                <p className="item-text pl-2">Disfrutar tu vida en el mar</p>
              </li>
              <li className="item-list">
                <p className="item-text pl-2">Lograr hacer crecer tu dinero</p>
              </li>
              <li className="item-list">
                <p className="item-text pl-2">Pasar tus vacaciones</p>
              </li>
              <li className="item-list">
                <p className="item-text pl-2">O tener una casa de retiro</p>
              </li>
            </ul>
          </div>
          <div className='block md:hidden flex justify-center px-0 px-md-5 px-lg-2'>
            <img src={playa} alt="playa" className="m-auto w-full" />
          </div>
        </div>

        <div className='hidden lg:block'>
          <div className='grid grid-cols-2 gap-2'>
            <div className='w-full'>
              <div className='w-full flex justify-start px-4 pt-8 pb-5 txt-blue'>
                <div className='px-5'>
                  Un residencial con las mejores amenidades y comodidades para los propietarios para que puedas:
                </div>
              </div>
              <div className='w-full flex justify-center pt-3'>
                <ul className="c-list">
                  <li className="item-list">
                    <p className="item-text pl-2">Disfrutar tu vida en el mar</p>
                  </li>
                  <li className="item-list">
                    <p className="item-text pl-2">Lograr hacer crecer tu dinero</p>
                  </li>
                  <li className="item-list">
                    <p className="item-text pl-2">Pasar tus vacaciones</p>
                  </li>
                  <li className="item-list">
                    <p className="item-text pl-2">O tener una casa de retiro</p>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className='flex justify-center p-4 items-center h-full'>
                <img src={playa} alt="playa" className="m-auto w-full" />
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}
