import React, { useRef, useState, useEffect } from 'react';

import terraza from '../../../../../assets/home/images/shop/image38.png'
import step1 from '../../../../../assets/home/images/example/paso1.png'
import step2 from '../../../../../assets/home/images/example/paso2.png'
import step3 from '../../../../../assets/home/images/example/paso3.png'
import step4 from '../../../../../assets/home/images/example/paso4.png'
import './Shopnow.css'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
const itemsGallery = [
    {
      image: step1,
      title: 'step1'
    },
    {
      image: step2,
      title: 'step2'
    },
    {
      image: step3,
      title: 'step3'
    },
    {
      image: step4,
      title: 'step4'
    },
]
export default function Shopnow(){
  const splideRef = useRef(null);
  const [progress, setProgress] = useState(0);

    // Update progress bar when the carousel moves
    useEffect(() => {
      const splideInstance = splideRef.current?.splide;
  
      if (splideInstance) {
        // Calculate the progress percentage based on the current slide and total slides
        const updateProgress = () => {
          const totalSlides = splideInstance.length;
          const currentIndex = splideInstance.index + 1; // 1-based index for human readability
          const percentage = (currentIndex / totalSlides) * 100;
          setProgress(percentage); // Update progress bar width
        };
  
        // Listen for the `move` event to update the progress bar
        splideInstance.on('move', updateProgress);
  
        // Initialize the progress on mount
        updateProgress();
  
        // Cleanup event listener on component unmount
        return () => {
          splideInstance.off('move', updateProgress);
        };
      }
    }, []);
    const brkpts = {
      1024: {
        perPage: 2,
      },
      768: {
        perPage: 1,
      },
      640: {
        perPage: 1,
      },
    };
    const options = {
      type: 'loop', // Enable loop playback
      perPage: 2, // Show 3 slides at a time
      arrows: true, // Show navigation arrows
      breakpoints: brkpts,
      perMove   : 1,
      pagination: false,
      cloneStatus:false,
      autoplay: true,
    };

      return(
        <div className="Shopnow">
            <div className="grid  grid-cols-1  justify-center items-end h-100 ">
                <div className='px-0'>
                    <img src={terraza} alt="" className="img-fund  w-full " />
                </div>
                <div className='w-full flex justify-center items-center h-full grid  grid-cols-1 lg:grid-cols-2 gap-4 py-7 lg:py-10'>
                    <div className='w-full px-0 px-md-4'>
                        <h1 className="title">
                            Financiamiento
                        </h1>
                    </div>
                    <div className='w-full xl:w-10/12 sm:px-0 lg:px-9'>
                        <p className="description px-3 px-md-5 px-lg-3 ">
                            Una de las principales ventajas que te ofrecemos es poder pagar tu terreno en <b>cómodos pagos mensuales sin intereses</b> por hasta 10 años.
                        </p>
                        <p className="desc-ejemplo">
                          Ejemplo:
                          <span className=''>
                          <svg width="124" height="4" viewBox="0 0 124 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 2H124" stroke="url(#paint0_linear_502_595)" stroke-width="3"/>
                              <defs>
                                <linearGradient id="paint0_linear_502_595" x1="0.000198569" y1="2.49993" x2="124.005" y2="2.49993" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#058CBF"/><stop offset="1" stop-color="#41CFE3"/>
                                </linearGradient>
                              </defs>
                          </svg>
                          </span>
                        </p>
                    </div>
                </div>
                
            </div>

            <div className='w-full flex p-4 justify-center items-center m-auto pt-4'>
            <div>
              <Splide hasTrack={ false } options={options} ref={splideRef}>
                <SplideTrack>
                {
                  itemsGallery.map((item, index) => (
                    <SplideSlide key={ index } >
                      <div className='item mx-auto' >
                        <img src={item.image} alt="" className='item-img mx-auto' />
                      </div>
                    </SplideSlide>
                  ))
                }
                </SplideTrack>
              </Splide>
              
              <div style={{ position: 'relative', width: '100%', height: '10px', backgroundColor: '#f0f0f0', marginBottom: '10px' }}>
                {/* Progress bar (dynamic width based on state) */}
                <div
                  style={{
                    width: `${progress}%`,
                    height: '100%',
                    backgroundColor: '#238C9FCC', // You can customize the color here
                    transition: 'width 0.3s ease-in-out',
                  }}
                ></div>
            </div>
            </div>
          </div>
        </div>
      );
}