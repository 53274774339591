import React, { useCallback, useEffect, useState } from 'react'
import './UsersTable.css'
import { deleteUser, getUsersByRole, getUsers } from '../../../../../services/UsersService';
import Pagination from '../../../../../components/pagination/Pagination';
import { useTranslation } from 'react-i18next';
import _ from "lodash"
import { FaFileExcel, FaTrash } from 'react-icons/fa6';
import { toast, ToastContainer } from 'react-toastify';
import Loading from '../../../../../components/loading/Loading';
import Modal from '../../../../../components/modal/Modal';
import RegisterDelete from '../../../register/components/registerDelete/RegisterDelete';
import { FaEdit } from 'react-icons/fa';
import Register from '../../../register/Register';
import { roles } from '../../../../../constants/roles';
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { useAuth } from '../../../../../hooks/useAuth';

export default function UsersTable(
  {role, random, onScroll}
) {

  const [meta, setMeta] = useState(null);
  const [users, setUsers] = useState([]);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [randomReceived, setRandomReceived] = useState(0);
  const [userToEdit, setUserToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const { t } = useTranslation();
  const { user } = useAuth();

  const downloadFile = useCallback((theResponse) => {
    const url = window.URL.createObjectURL(new Blob([theResponse]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `users.xlsx`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }, []);

  const getUserList = useCallback(async (page, download = undefined) => {
    let response = null;
    setLoading(true);
    if (role === roles.ADMIN) {
      response = await getUsersByRole(page, role, download);
    } else {
      response = await getUsers(page, download);
    }
    setLoading(false);
    if (!download) {
      if (response.data && response.data.data) {
        response.data.data.forEach(theUser => {
          theUser.show = false;
        });
        setUsers(response.data.data);
        setMeta(response.data.meta);
      }
    } else {
      downloadFile(response.data);
    }
  }, [downloadFile, role]);

  /**
   * Show alert confirm to delete
   */
  const handleDelete = useCallback((theUser) => {
    setUserToDelete(theUser);
    setShowDelete(true);
  }, []);

  /**
   * Handle cancel delete section
   */
  const handleCancelDelete = useCallback(() => {
    setUserToDelete(null);
    setShowDelete(false);
  }, []);

  /**
   * Handle confirm delete
   */
  const handleConfirmDelete = useCallback(async() => {
    try {
      setLoading(true);
      const response = await deleteUser(userToDelete.id);
      setLoading(false);
      if (response.status === 200) {
        toast.success(t('registerPage.delete'));
        setShowDelete(false);
        setUserToDelete(null);
        getUserList(1);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, [getUserList, t, userToDelete]);

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback((pageSelected) => {
    if (meta && meta.current_page !== pageSelected) {
      getUserList(pageSelected);
    }
  }, [getUserList, meta]);

  /**
   * Handle show form to send values to register component
   */
  const handleShowForm = useCallback((theUser) => {
    const data = {
      id: theUser.id ? theUser.id : undefined,
      name: theUser.name,
      lastName: theUser.last_name,
      countryCode: theUser.country_code,
      phone: theUser.phone,
      email: theUser.email,
      zipCode: theUser.client ? theUser.client.zip_code : undefined,
      address: theUser.client ? theUser.client.full_address : undefined
    };
    setUserToEdit(data);
    setShowForm(true);
    onScroll();
  }, [onScroll]);

  /**
   * Handle close modal and reload users list
   */
  const handleCloseModal = useCallback(() => {
    getUserList(1);
    setShowForm(false);
    setUserToEdit(null);
  }, [getUserList]);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const handleArrowClick = useCallback((userSelected, show) => {
    const theIndex = users.findIndex((theUser) => theUser.id === userSelected.id);
    const currentUsers = [...users];
    currentUsers[theIndex].show = show;
    setUsers(currentUsers);
  }, [users]);

  const handleDownload = useCallback(async () => {
    getUserList(1, 'excel');
  }, [getUserList]);

  useEffect(() => {
    if (randomReceived !== random) {
      setRandomReceived(random);
      getUserList(1);
    }
    if (user.user.roles[0].name.toLowerCase() === roles.SUPER_ADMIN) {
      setCanDelete(true);
    }
  }, [getUserList, random, randomReceived, role, user.user.roles]);

  return (
    <div className='UsersTable'>
      { loading && <Loading /> }

      <div className='pt-0'>
        <FaFileExcel
          className='excel-icon'
          onClick={handleDownload}
        />
      </div>

      {/* Vista para móvil */}
      <div className='block lg:hidden'>
        {
          _.map(users, (theUser, index) => (
            <div
              key={index}
              className='card-info'
            >
              <div className='flex justify-between px-4'>
                <div className='text-sm'>
                  {theUser.name} { theUser.last_name }
                </div>
                <div>
                  {
                    !theUser.show &&
                    <BiSolidDownArrow
                      onClick={() => handleArrowClick(theUser, true)}
                    />
                  }
                  {
                    theUser.show &&
                    <BiSolidUpArrow 
                      onClick={() => handleArrowClick(theUser, false)}
                    />
                  }
                </div>
              </div>
              {
                theUser.show &&
                <div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('registerPage.phone') }
                    </div>
                    <div className='text-left field-value'>
                      { theUser.phone }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('registerPage.email') }
                    </div>
                    <div className='text-left field-value'>
                      { theUser.email }
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-4  my-2'>
                    <div className='field-title'>
                      { t('global.options') }
                    </div>
                    <div className='text-left field-value'>
                      <div className='flex justify-end gap-2'>
                        <div
                          className='cursor-pointer text-primary'
                          onClick={() => handleShowForm(theUser)}
                        >
                          <FaEdit />
                        </div>
                        {
                          canDelete &&
                          <div
                            className='cursor-pointer text-primary'
                            onClick={() => handleDelete(theUser)}
                          >
                            <FaTrash />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          ))
        }
        <div className='mt-1'>&nbsp;</div>
      </div>

      {/* Vista para web */}
      <div className='hidden lg:block'>
        <div className='table-section'>
          <table className="w-full mt-5 table-auto">
            <thead className='text-black bg-header'>
              <tr>
                <td className='p-2'>
                  { t('registerPage.id') }
                </td>
                <td className='p-2'>
                  { t('registerPage.name') }
                </td>
                <td className='p-2'>
                  { t('registerPage.lastName') }
                </td>
                <td className='p-2'>
                  { t('registerPage.phone') }
                </td>
                <td className='p-2'>
                  { t('registerPage.email') }
                </td>
                <td>
                  { t('global.options') }
                </td>
              </tr>
            </thead>
            <tbody className='text-cell'>
            {
              _.map(users, (theUser, index) => (
                <tr
                  className={`border-b border-white text-sm ${index % 2 === 0 ? 'bg-white' : 'bg-header'}`}
                  key={theUser.id}
                >
                  <td className='p-2 text-primary'>
                    { theUser.id }
                  </td>
                  <td className='p-2'>
                    { theUser.name }
                  </td>
                  <td className='p-2'>
                    { theUser.last_name }
                  </td>
                  <td className='p-2'>
                    ({ theUser.country_code }) { theUser.phone }
                  </td>
                  <td className='p-2'>
                    { theUser.email }
                  </td>
                  <td className='p-2'>
                    <div className='flex gap-2'>
                      <div
                        className='cursor-pointer text-primary'
                        onClick={() => handleShowForm(theUser)}
                      >
                        <FaEdit />
                      </div>
                      {
                        canDelete &&
                        <div
                          className='cursor-pointer text-primary'
                          onClick={() => handleDelete(theUser)}
                        >
                          <FaTrash />
                        </div>
                      }
                    </div>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>

          <div className='flex justify-center w-full mt-4 mb-4'>
            <Pagination
              meta={meta}
              onPageSelected={onPageSelected}
            />
          </div>
        </div>
      </div>
      
      {
        showDelete &&
        <Modal onCloseModal={() => setShowDelete(false)} type="small">
          <RegisterDelete onCancel={handleCancelDelete} onConfirm={handleConfirmDelete} />
        </Modal>
      }

      {
        showForm &&
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
          title={userToEdit ? t('registerPage.edit') : t('registerPage.add')}
        >
          <Register
            userToEdit={userToEdit}
            onSuccess={handleSuccess}
          />
        </Modal>
      }

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
